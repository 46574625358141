import {Controller} from "stimulus"

function swap(target, item) {
  if (target && target !== item) {
    const positionComparison = target.compareDocumentPosition(item);
    if (positionComparison & 4) {
      target.insertAdjacentElement('beforebegin', item);
    } else if (positionComparison & 2) {
      target.insertAdjacentElement('afterend', item);
    }
  }
}

export default class extends Controller {
  dragstart(event) {
    if (this.element !== event.target.parentElement) return;

    const target = event.target;
    event.dataTransfer.effectAllowed = "move";

    // update element style after drag has begun
    setTimeout(() => target.dataset.dragging = "");
  }

  dragover(event) {
    const item = this.dragItem(event);
    if (!item) return;

    swap(this.dropTarget(event), item);
    event.preventDefault();
    return true;
  }

  dragenter(event) {
    event.preventDefault();
  }

  drop(event) {
    const item = this.dragItem(event);
    if (!item) return;

    event.preventDefault();
    delete item.dataset.dragging;
    swap(this.dropTarget(event), item);
    this.reindex();
  }

  dragend(event) {
    const item = this.dragItem(event);
    if (!item) return;

    event.preventDefault();
    delete item.dataset.dragging;
    this.reset();
  }

  dragItem(event) {
    return this.element.querySelector("[data-dragging]");
  }

  dropTarget(event) {
    return event.target.closest("[data-controller~='editable-list'] > *");
  }

  reindex() {
    this.element.dispatchEvent(new CustomEvent("reindex"));
  }

  reset() {
    this.element.dispatchEvent(new CustomEvent("reset"));
  }
}
