import {Controller} from "stimulus"
import * as L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
import MarkerIcon from '../../stylesheets/images/marker-icon.png';
import MarkerIconx2 from '../../stylesheets/images/marker-icon-2x.png';
import MarkerShadow from '../../stylesheets/images/marker-shadow.png';
import ProjectFeatureLoader from "../utility/map/project_feature_loader";

L.Icon.Default.mergeOptions({
  iconUrl:       MarkerIcon,
  iconRetinaUrl: MarkerIconx2,
  shadowUrl:     MarkerShadow,
});

//
// MAP FOR PROJECT SHOW PAGE
//

class ProjectMapController extends Controller {
  static targets = [];

  connect() {
    const project = JSON.parse(this.element.dataset.project);

    this.map = L.map('project-map').setView([project.latitude, project.longitude], 13);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoia2F0YWx5c3QiLCJhIjoiY2tiYnJxMHhwMDNtZzJ4bjBqMHdqOXc1dyJ9.27q0hXTUDT56eMy-Rsu3mQ'
    }).addTo(this.map);

    new ProjectFeatureLoader({ projects: [project] }).load(this.map);
  }

}

export default ProjectMapController;
