import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["keyline", "background"]

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.keylineTarget.checked) {
      Array.from(this.backgroundTargets).map(t => t.classList.add("hidden"));
    } else {
      Array.from(this.backgroundTargets).map(t => t.classList.remove("hidden"));
    }
  }
}
