import { Controller } from "stimulus";

export default class ImageFieldController extends Controller {
  static targets = ["image", "input"];

  setDestroy(event) {
    event.preventDefault();

    this.imageTarget.classList.add("hidden");
    this.inputTarget.value = true;
  }
}
