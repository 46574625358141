import {Controller} from "stimulus"
import MicroModal from "micromodal"
import {childrenList, index, setIndex, depth, setDepth, RulesEngine} from "../utils/rules-engine";

const objectType = "rich-content";

function item(event) {
  return event.target.closest("[data-rich-content-item]");
}

/**
 * Returns content nodes that are visible and logically nested below the given node.
 *
 * @param node
 * @returns {Element[]}
 */
function visibleDescendants(node) {
  const descendants = [];

  for (let sibling = node.nextElementSibling; sibling && depth(sibling, objectType) > depth(node, objectType); sibling = sibling.nextElementSibling) {
    descendants.push(sibling);
  }

  return descendants;
}

/**
 * Returns content nodes that have been collapsed into the given node.
 *
 * @param node
 * @returns {Element[]}
 */
function hiddenDescendants(node) {
  return Array.from(childrenList(node, objectType).children);
}

function setOpen(node, open = true) {
  node.querySelector("[data-rich-content-open-input]").value = (open ? 1 : 0);
}

function setDestroy(node, destroy = true) {
  node.dataset.destroy = destroy;
  node.querySelector("[data-rich-content-destroy-input]").value = (destroy ? 1 : 0);
}

function blur(event) {
  event.target.closest("button").blur();
}

export default class extends Controller {
  static targets = ["menu", "modal"]

  connect() {
    this.reindex();
    this.focusFirstInput();
  }

  reindex() {
    this.navItems().map((node, index) => setIndex(node, index, objectType));
    this.updateNavItems();
  }

  focusFirstInput() {
    let openNodes = this.navItems().filter(e => Array.from(e.classList).includes("open"));

    if (Array.isArray(openNodes) && openNodes.length) {
      let firstInput = openNodes[0]
        .querySelector("input:not([type=hidden]), select:not([type=hidden]), textarea:not([type=hidden])");

      firstInput.focus();
    }
  }

  reset() {
    this.navItems().sort(compare).forEach(node => this.menuTarget.appendChild(node));
  }

  navItems() {
    return Array.from(this.menuTarget.querySelectorAll("[data-rich-content-index]"));
  }

  updateNavItems() {
    setTimeout(() => new RulesEngine(objectType).update(this.navItems()), 0);
  }

  open(event) {
    setOpen(item(event));
  }

  close(event) {
    setOpen(item(event), false);
  }

  new(event) {
    // new inputs are disabled to prevent submission until the modal shows.
    this.modalTarget.querySelectorAll("input").forEach(input => input.removeAttribute("disabled"));

    MicroModal.show(this.modalTarget.id, {
      awaitCloseAnimation: true, // set to false, to remove close animation
      onClose: this.closeModal.bind(this)
    });
  }

  add(event) {
    // dismiss (and disable) unless an component type has been selected
    if (!this.modalTarget.querySelector("input:checked")) {
      MicroModal.close(this.modalTarget.id);
    } else {
      event.target.closest("form").requestSubmit();
    }
  }

  closeModal(event) {
    this.modalTarget.querySelectorAll("input").forEach(input => input.setAttribute("disabled", ""));
  }

  remove(event) {
    const node = item(event);
    const shouldDestroy = node.dataset.destroy === "false"

    setDestroy(node, shouldDestroy);

    hiddenDescendants(node).forEach(node => setDestroy(node, shouldDestroy));

    blur(event);
    event.preventDefault();
  }

  nest(event) {
    const node = item(event);

    setDepth(node, depth(node, objectType) + 1, objectType);

    hiddenDescendants(node).forEach(node => setDepth(node, depth(node, objectType) + 1, objectType));

    this.updateNavItems();

    blur(event);
    event.preventDefault();
  }

  deNest(event) {
    const node = item(event);

    setDepth(node, depth(node, objectType) - 1, objectType);

    hiddenDescendants(node).forEach(node => setDepth(node, depth(node, objectType) - 1, objectType));

    this.updateNavItems();

    blur(event);
    event.preventDefault();
  }

  collapse(event) {
    const node = item(event);
    const children = childrenList(node, objectType);

    visibleDescendants(node).forEach(e => children.appendChild(e));

    this.updateNavItems();

    blur(event);
    event.preventDefault();
  }

  expand(event) {
    const node = item(event);

    hiddenDescendants(node).reverse().forEach(item => node.insertAdjacentElement('afterend', item));

    this.updateNavItems();

    blur(event);
    event.preventDefault();
  }
}
