import * as esri from "esri-leaflet";
import * as L from 'leaflet';
import Rails from "@rails/ujs";

const serviceUrl = "https://pirsa.geohub.sa.gov.au/server/rest/services/AgInsight_Services/SIS_AgInsight_Investment_P/MapServer/";
const markersServiceId = 0;
const linesServiceId = 1;
const areasServiceId = 2;

class ProjectFeatureLoader {

  constructor(options) {
    const { projects, zoom = true } = options;

    this.projects = projects;
    this.zoom = zoom;
    this.inFlight = 0;
  }

  /** Add feature layers to the map */
  load(map) {
    this.map = map;
    this.bounds = L.latLngBounds([]);
    this.loadPirsaProjects();
    this.loadNonPirsaProjects();
    this.updateZoom();
  }

  /** Add projects whose PIRSA data has been approved **/
  loadPirsaProjects(){
    const projects = this.projects.filter(p => p.display_pirsa_map);

    if(projects.length === 0) return;

    const where = 'id in (' + projects.map(p => p.id).join(',') + ')';

    const markersLayer = esri.featureLayer({
      url: serviceUrl + markersServiceId,
      where: where,
    }).bindPopup("Loading...")
      .on('click', (e) => {
        const projectId = e.layer.feature.properties.ID;
        onMarkerClick(e, projectId);
      })
      .addTo(this.map);

    this.loadFeatures(markersLayer);

    const areasLayer = esri.featureLayer({
      url: serviceUrl + areasServiceId,
      where: where,
    }).addTo(this.map);

    this.loadFeatures(areasLayer);

    const linesLayer = esri.featureLayer({
      url: serviceUrl + linesServiceId,
      where: where,
    }).addTo(this.map);
  }

  /** Add projects whose PIRSA data hasn't been approved but that have lat/lng data **/
  loadNonPirsaProjects(){
    const projects = this.projects.filter(p => !p.display_pirsa_map && p.latitude && p.longitude);

    if(projects.length === 0) return;

    const markers = projects.map(project => {
      return L.marker([project.latitude, project.longitude])
        .bindPopup("Loading...")
        .on('click', (e) => onMarkerClick(e, project.id));
    });
    const markersLayer = L.featureGroup(markers).addTo(this.map);
    this.addFeature(markersLayer);
  }

  /**
   * Schedule feature loading for the given feature layer for calculating bounds.
   *
   * The PIRSA endpoint does not support calculating bounds on the server, client
   * side bounds calculation is based on
   * https://esri.github.io/esri-leaflet/examples/zooming-to-all-features-2.html
   */
  loadFeatures(layer) {
    if (!this.zoom) return;

    this.inFlight += 1;
    layer.once('load', e => {
      layer.eachFeature(f => this.addFeature(f));
      this.inFlight -= 1;
      this.updateZoom();
    });
  }

  /**
   * Adds features from the given layer to the loader's bounds.
   * The PIRSA endpoint does not support calculating bounds on the server.
   * Client side bounds based on https://esri.github.io/esri-leaflet/examples/zooming-to-all-features-2.html
   */
  addFeature(feature) {
    if (feature.getBounds) {
      this.bounds.extend(feature.getBounds());
    } else if (feature.getLatLng) {
      this.bounds.extend(feature.getLatLng());
    } else {
      throw new Error("Unexpected feature: " + feature);
    }
  }

  /**
   * Update the map's zoom to fit the bounds of the feature which have been loaded.
   * If the `inFlight` semaphore shows that layers are still loading then no update will be performed.
   */
  updateZoom() {
    if (this.inFlight !== 0) return;
    if (!this.bounds.isValid()) return;
    if (!this.zoom) return;

    this.map.fitBounds(this.bounds.pad(0.13));
  }
}

function onMarkerClick(e, projectId) {
  const popup = e.target.getPopup();
  const url = '/map/projects/' + projectId;
  Rails.ajax({
    url: url,
    type: "GET",
    success: (tile) => {
      popup.setContent(tile.html);
      popup.update();
    }
  });
}

export default ProjectFeatureLoader;
