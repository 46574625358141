import { Controller } from "stimulus";
import populateField from "../utils/populate-fIeld";

export default class OfferingController extends Controller {
  static targets = ["title", "slug"];

  populateSlug() {
    populateField(this.titleTarget, this.slugTarget);
  }
}
