import { Controller } from "stimulus";

/**
 * Custom search component for the first datatable on the page.
 */
export default class extends Controller {
  static targets = ["input"];

  connect() {
    if (this.inputTarget.value.length > 0) {
      this.search(); // re-do search on reconnect
    }
  }

  search() {
    this.datatable().search(this.inputTarget.value).draw();
  }

  datatable() {
    return $("[data-controller=datatable]").DataTable();
  }

  shortcut(e) {
    // '/' to search, enjoy Jamie
    if (e.which === 191 && e.target === document.body) {
      this.inputTarget.focus();
    }
  }
}
