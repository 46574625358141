// This file bootstraps a DTI webpack environment, with all of the shared
// dependencies loaded and ready to use. Use `require` instead of `include`
// to ensure that symbols are loaded into the global namespace:
//
//   require("../src/bootstrap.js")

import "@stimulus/polyfills";
import "wicg-inert/dist/inert";

// Direct uploads, this need to be added before activestorage.start as our form submit event listener
// should be added in front of activestorage one, so we have chance to cancel form submission in case there is
// pending file uploading.
import DirectUploads from "../src/direct_uploads.js";

DirectUploads.start();

import Rails from "@rails/ujs";

Rails.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@rails/actiontext")

// handle generic ajax errors
import "../src/errors.js";

// Choices.js
import "../src/choices.js";

// Pagy.js
import "../src/pagy.js.erb";

// Turbolinks persist scroll
import "./tl_persist_scroll";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'images/rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Modals
import "micromodal";
