import {Controller} from "stimulus"

class ShowHideController extends Controller {
  static targets = [
    "investBoolean",
    "investFields",
    "exportBoolean",
    "exportFields",
    "userTypeLabel",
    "exporterBoolean",
    "readinessTypeField",
  ]

  connect() {
    this.toggleInvestFields();
    this.toggleExportFields();
    this.toggleReadinessTypeField();
  }

  toggleInvestFields() {
    let investBooleanChecked = this.investBooleanTarget.checked;
    let exportBooleanChecked = this.exportBooleanTarget.checked;

    if (investBooleanChecked) {
      this.investFieldsTarget.classList.remove("hidden");
    } else {
      this.investFieldsTarget.classList.add("hidden");
    }

    if (investBooleanChecked || exportBooleanChecked) {
      this.userTypeLabelTarget.classList.remove("hidden");
    } else {
      this.userTypeLabelTarget.classList.add("hidden");
    }
  }

  toggleExportFields() {
    let investBooleanChecked = this.investBooleanTarget.checked;
    let exportBooleanChecked = this.exportBooleanTarget.checked

    if (exportBooleanChecked) {
      this.exportFieldsTarget.classList.remove("hidden");
    } else {
      this.exportFieldsTarget.classList.add("hidden");
    }

    if (investBooleanChecked || exportBooleanChecked) {
      this.userTypeLabelTarget.classList.remove("hidden");
    } else {
      this.userTypeLabelTarget.classList.add("hidden");
    }
  }

  toggleReadinessTypeField() {
    let exporterBooleanChecked = this.exporterBooleanTarget.checked

    if (exporterBooleanChecked) {
      this.readinessTypeFieldTarget.classList.remove("hidden");
    } else {
      this.readinessTypeFieldTarget.classList.add("hidden");
    }
  }
}

export default ShowHideController;
