import { Controller } from "stimulus"
import { Transition } from "./utils/transition";

class ShowHideController extends Controller {
  static targets = ["content", "trigger"];

  disconnect() {
    this.cancel();
  }

  toggle(event, force = false) {
    event.preventDefault();

    const hide = this.contentTarget.toggleAttribute("data-hide") || force;
    this.triggerTarget.toggleAttribute("data-toggle-active", !hide)

    // cancel previous animation, if any
    this.cancel();

    const transition = this.transition = new Transition(this.contentTarget);
    hide ? transition.collapse() : transition.expand();

    transition.start();

    this.triggerTarget.dispatchEvent(new Event(hide ? "hide" : "show"));
  }

  hide(event) {
    event.preventDefault();
    if (!this.contentTarget.hasAttribute("data-hide")) {
      this.toggle(event, true)
    }
  }

  cancel() {
    if (this.transition) this.transition.cancel();
  }
}

export default ShowHideController;
