import { Controller } from "stimulus"
import { Transition } from "./utils/transition";

/**
 * Controller for showing and hiding flash messages. Messages are set to hide by
 * default so that the can animate in when the page loads. If a user closes a
 * message or they are closed automatically then this is stored in the dom so
 * that the message will not re-appear on turbolinks history navigation.
 */
class FlashController extends Controller {

  /**
   * Animate flash messages in, if they are not already showing or dismissed.
   * Schedule close for informational messages.
   */
  connect() {
    if (!this.element.hasAttribute("data-hide")) return; // no animations required

    // show the flash message
    this.show = new Transition(this.element, { delay: 400 }).fadeIn().start(() => {
      this.element.removeAttribute("data-hide");
    });

    // trigger auto-hide for informative messages
    if (this.element.hasAttribute("data-duration")) {
      this.duration = setTimeout(() => this.close(), this.element.dataset.duration);
    }
  }

  /** Clean up any timers created by connect or close */
  disconnect() {
    if (this.show) this.show.cancel();
    if (this.duration) clearTimeout(this.init);
    if (this.hide) this.hide.cancel();
  }

  /**
   * Close the flash message in response to a timeout, or user dismissing.
   */
  close(event) {
    if (event) event.preventDefault();
    if (this.transition) return; // already closing/closed

    // set `data-hide` so that CSS will hide the element once transition completes
    this.element.toggleAttribute("data-hide", true);

    this.hide = new Transition(this.element, { delay: 800 })
        .collapse()
        .slideOut("right")
        .fadeOut()
        .addCallback("complete",e => this.teardown(e))
        .start();
  }

  /**
   * Remove notifications so that they will not appear in turbolinks cache.
   */
  teardown(_event) {
    this.element.remove();
  }
}

export default FlashController;
