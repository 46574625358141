import { Controller } from "stimulus";
import populateField from "../utils/populate-fIeld";

export default class PersonController extends Controller {
  static targets = ["name", "slug"];

  populateSlug() {
    populateField(this.nameTarget, this.slugTarget);
  }
}
