/**
 * Populates output field with a parameterized string of the input field value
 * Replicates the .parameterize function from Rails with a few additional tweaks
 *
 * @param input {HTMLElement} The input field element
 * @param output {HTMLElement} The output field element
 */
function populateField(input, output) {
  output.value = input.value.toLowerCase()                   // lowercase
    .trim()                                                  // strip leading/trailing whitespace
    .replace(/\s+/gi, "-")           // replace whitespace with "-"
    .replace(/[^a-z0-9\-]/g, "")     // replace anything not letter/number/separator with ""
    .replace(/^-+|-+$/gi, "");       // strip leading/trailing "-"
}

export default populateField;
