import { Controller } from "stimulus"

function index(node) {
  return node.dataset.repeaterIndex;
}

function setIndex(node, index) {
  node.dataset.repeaterIndex = index;
  node.querySelector("[data-repeater-index-input]").value = index;
}

function compare(a, b) {
  return index(a) - index(b);
}

function item(event) {
  return event.target.closest("[data-repeater-item]");
}

function setOpen(node, open = true) {
  node.querySelector("[data-repeater-open-input]").value = (open ? 1 : 0);
}

function setDestroy(node, destroy = true) {
  node.dataset.destroy = destroy;
  node.querySelector("[data-repeater-destroy-input]").value = (destroy ? 1 : 0);
}

export default class RepeaterController extends Controller {
  static targets = ["menu", "update"]

  connect() {
  }

  reindex() {
    this.navItems().map((node, index) => setIndex(node, index + 1))
  }

  reset() {
    this.navItems().sort(compare).forEach(node => this.menuTarget.appendChild(node));
  }

  navItems() {
    return Array.from(this.menuTarget.querySelectorAll("[data-repeater-index]"));
  }

  open(event) {
    setOpen(item(event));
  }

  close(event) {
    setOpen(item(event), false);
  }

  remove(event) {
    setDestroy(item(event));
    event.preventDefault();
  }
}
