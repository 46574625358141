addEventListener("error", event => {
  // Alert file upload error in trix text editor
  const { message } = event
  if (message && message.includes("Direct upload failed")) {
    alert("File upload failed! Please check your network, refresh your page and try again.")
  }
});

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)

  alert("File upload failed! please check your network, refresh your page and try again.")
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

function didSubmit(event) {
  if (event.target.querySelector(".direct-upload--pending")) {
    event.preventDefault();
    event.stopImmediatePropagation();
    console.log("submit event cancelled");
  }
}

function start() {
  // Our code relies on this event firing before Rails' activestorage listener.
  // ActiveStorage uses capture so we need to also.
  // (see https://github.com/rails/rails/commit/e75b0094d0e71242667b6e5846438220edd624da)
  document.addEventListener("submit", event => didSubmit(event), { capture: true });
}

export default { start };
