import { Controller } from "stimulus";
import populateField from "../utils/populate-fIeld";

export default class GlobalOfficeController extends Controller {
  static targets = ["displayTitle", "slug"];

  populateSlug() {
    populateField(this.displayTitleTarget, this.slugTarget);
  }
}
