import {Controller} from "stimulus";

export default class OrderableListController extends Controller {

  reindex() {
    this.navItems().map((node, index) => this.setIndex(node, index));
  }

  navItems() {
    return Array.from(this.element.querySelectorAll("[data-controller~='orderable-list'] > *"));
  }

  setIndex(node, index) {
    node.querySelector("input[data-input-index]").value = index;
  }
}
