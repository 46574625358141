import { Controller } from "stimulus";

export default class SlugFieldController extends Controller {
  static targets = ["input"];

  enable(event) {
    event.preventDefault();

    this.inputTarget.removeAttribute("readonly");
  }
}
