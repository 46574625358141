/**
 * A controller for upload file immediately on file selection instead of wait till form submission (default behaviour
 * of activestorage direct_upload)
 *
 * It would alert a generic message for any errors. End user is expected to refresh the page and try again
 * instead of trying to re-upload the file/re-submit the form for now. Some errors are recoverable (i.e timeout), some
 * errors are not (i.e authentication error), we might improve error handling if need to in the future
 *
 * There is a form submit event listener (check direct_upload.js#start) to cancel form submission if there is any file
 * upload ongoing/with error
 *
 * This controller is integrated with image_field (AccessibleFormBuilder.image_field) by default
 *
 */
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class DirectUploadController extends Controller {
  upload() {
    const file = this.element.files[0];
    if (!file) {
      return
    }

    const upload = new DirectUpload(file, this.element.dataset.directUploadUrl, this)

    let directUploadFeedback = this.element.parentNode.querySelector(".direct-upload")
    if (directUploadFeedback) {
      this.element.parentNode.removeChild(directUploadFeedback)
    }

    this.element.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${upload.id}" class="direct-upload  direct-upload--pending">
      <div id="direct-upload-progress-${upload.id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
    `)

    upload.create((error, blob) => {
      directUploadFeedback = this.element.previousElementSibling
      if (error) {
        directUploadFeedback.classList.add("direct-upload--error")
        directUploadFeedback.setAttribute("title", error)

        alert("File upload failed! Please check your network, refresh your page and try again.")
      } else {
        let hiddenInput = this.element.parentNode.querySelector(`input[type="hidden"][name="${this.element.name}"]`)

        if (!hiddenInput) {
          hiddenInput = document.createElement("input");
          this.element.parentNode.append(hiddenInput);
        }
        hiddenInput.setAttribute("type", "hidden")
        hiddenInput.setAttribute("name", this.element.name)
        hiddenInput.setAttribute("id", this.element.id)
        hiddenInput.setAttribute("value", blob.signed_id)

        directUploadFeedback.classList.add("direct-upload--complete")
        directUploadFeedback.classList.remove("direct-upload--pending")

        this.element.disabled = true
      }
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", event => {
      const progress = event.loaded / event.total * 100;
      const progressElement = this.element.previousElementSibling.querySelector(".direct-upload__progress")
      progressElement.style.width = `${progress}%`
    });
  }
}
